import React from "react";

import "./Intro.css";
function Intro() {
  return (
    <div>
      <p className="p_Intro">
        As an experienced iOS developer with over 10 years in the industry, I
        have developed and published seven apps on the App Store  , each
        crafted with a focus on user experience, functionality, and sleek
        design. My journey in development has been a continuous cycle of
        learning, refining, and creating. Currently, I'm working on my eighth
        app, pushing the boundaries of what I can do with every new project. My
        approach to app development is simple: adapt, evolve, and stay ahead of
        the technological curve. 
        <br/>


        
        <br/>
        In recent years, I have expanded my expertise
        to web development, bringing the same level of dedication and passion to
        this new frontier. I’ve published my own website and built projects that
        merge creativity with practicality. Alongside this, I’ve dedicated
        myself to teaching others by creating courses on various technical
        skills. I actively mentor students through live classes, helping
        aspiring developers navigate the complexities of coding and development,
        while sharing insights I've gathered from over a decade of experience.
         
        <br/>
        <br/>
         I recently launched Antfire Labs, a startup focused on app and web
        development. The vision behind Antfire Labs is to help bring other
        people’s ideas to life. My goal is to connect with like-minded
        individuals who share my passion for innovation, hard work, and keeping
        up with emerging trends. I believe that the best products are created
        when passionate people come together with a shared vision, and I’m
        always eager to collaborate with others who strive for excellence in
        tech.
        <br/>
        <br/>

        Beyond the world of coding, I have a deep-rooted passion for
        farming, and I am committed to revolutionizing agriculture through
        technology. My vision is to incorporate cutting-edge tools and
        methodologies into farming practices, merging two seemingly different
        worlds. I believe that with the right integration of tech into farming,
        we can not only make agriculture more efficient but also more
        sustainable, opening doors to innovations that benefit both people and
        the environment.
        <br/>
        <br/>
         As a person, I’m constantly seeking knowledge, whether
        through coding, teaching, or reading books on philosophy and psychology.
        I believe that continuous learning is the key to growth, both
        professionally and personally. My curiosity and love for understanding
        human behavior and the world around me fuel my creativity in both tech
        and life. Every project I work on is a reflection of that curiosity—a
        way to explore new ideas, solve problems, and push boundaries.
      </p>
    </div>
  );
}

export default Intro;
