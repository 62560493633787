import logo from './logo.svg';
import './App.css';
import Home from "./Home.js"

function App() {
  return (
   
    <>
    <Home> </Home>

    </>
  );
}

export default App;
