import React from "react";
import Intro from "./Intro";
import "./UpperContent.css";
function UpperContent() {
  return (
    <div>
     <div className="content">
            <div className="css-houdini voronoi">
              <header>
                <div className="header-content">
                  <div className="fire-ice-text"></div>
                  <p className="site-subtitle"> Modern Technologist & Farmer</p>
                </div>
                <nav>
                  <ul className="navbar">
                    <li>
                      <a href="#headline">Headlines</a>
                    </li>
                    <li>
                      <a href="#technology">Technology</a>
                    </li>
                    <li>
                      <a href="#agriculture">Agriculture</a>
                    </li>
                    <li>
                      <a href="#projects">Projects</a>
                    </li>
                    <li>
                      <a href="#mentorship">Mentorship</a>
                    </li>
                    <li>
                      <a href="#quotes">Quotes</a>
                    </li>
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </nav>
              </header>

              <div className="">
                <section id="headline" className="background-content">
                  <article>
                    <h2 className="headline-title">About Vijayvir Singh </h2>
                    <div className="headline-content">
                      <Intro />
                    </div>
                  </article>
                </section>

                <section id="technology" className="background-content">
  <h2>Technology & Innovation</h2>
  <div className="article-grid">
      <article className="article-container">
          <h3 className="article-title">The Future of React.js in Web Development</h3>
          <p>Deep dive into advanced React.js concepts and how they are shaping the future of web development.</p>
      </article>
      <article className="article-container">
          <h3 className="article-title">Building Scalable iOS Applications</h3>
          <p>Explore best practices in creating responsive and scalable iOS apps that stand the test of time.</p>
      </article>
      <article className="article-container">
          <h3 className="article-title">Dynamic Web Applications with Modern Tech</h3>
          <p>Learn how to leverage the latest web technologies to build dynamic, user-friendly web applications.</p>
      </article>
  </div>
</section>

                <section id="agriculture" className="background-content">
                  <h2>Agriculture & Rajifarm.com</h2>
                  <article className="article-container">
                    <h3 className="article-title">
                      Rajifarm.com: A Legacy of Innovation in Agriculture
                    </h3>
                    <p>
                      Discover the story behind Rajifarm.com, a nursery farm
                      managed by Vijayvir Singh and his family, where tradition
                      meets modern farming techniques.
                    </p>
                  </article>
                  <article className="article-container">
                    <h3 className="article-title">
                      Sustainable Farming Practices
                    </h3>
                    <p>
                      How I integrates sustainable farming practices to enhance
                      productivity and environmental stewardship at
                      Rajifarm.com.
                    </p>
                  </article>
                </section>

                <section id="projects" className="background-content">
                  <h2>Featured Projects</h2>
                  <div className="projects-gallery">
                    <div className="article-container">
                      <h3>My Apple Apps</h3>
                      <p> Apps made by me.</p>
                      <a
                        href="https://apps.apple.com/jp/developer/vijayvir-singh/id1469680736?l=en"
                        target="_blank"
                        class="button-Cardss"
                      >
                        Visit My Apple Developer Profile
                      </a>
                    </div>

                    <div className="article-container">
                      <h3>Rajifarm</h3>
                      <p>
                        The official website for our family-owned nursery farm.
                      </p>
                      <a
                        href="https://rajifarm.com/"
                        target="_blank"
                        class="button-Cardss"
                      >
                        {" "}
                        Rajifarm.com{" "}
                      </a>
                    </div>
                  </div>
                </section>

                <section id="mentorship" className="background-content">
                  <h2>Mentorship</h2>
                  <article className="article-container">
                    <h3 className="article-title">
                      Guiding the Next Generation of Technologists
                    </h3>
                    <p>
                      I role as a mentor, helping computer science students and
                      young professionals navigate their careers in technology
                      and agriculture.
                    </p>
                  </article>
                </section>

                <section id="quotes" className="background-content">
                  <h2>Inspirational Leadership Quotes</h2>
                  <p className="p_paragraph">
                    He loves to read Leadership quotes, spiritual thoughts and
                    internet surfing.
                  </p>

                  <div className="article-container">
                    <h4>Some of Quotes by vijayvir</h4>
                    <blockquote>
                      "Make your internal universe beautiful,universe will make
                      you beautiful." - Vijayvir Singh
                    </blockquote>
                    <blockquote>
                      "If you have the right vision, you can find place in any
                      corner of the world." - Vijayvir Singh
                    </blockquote>
                    <blockquote>
                      "Any thing we can add in someone's life is... Good
                      memories" - Vijayvir Singh
                    </blockquote>
                    <blockquote>
                      "When you feel alone, you're already poised to win the
                      race. In solitude, you have a blank canvas to create your
                      masterpiece, free from distractions, and empowered to make
                      your own mark." - Vijayvir Singh
                    </blockquote>
                    <blockquote>
                      "Life is a labyrinth of words; it hinges on how deeply you
                      delve into their meaning and intricacies." - Vijayvir
                      Singh
                    </blockquote>
                  </div>
                </section>

                <section id="contact" className="background-content">
                  <h2>Contact</h2>

                  <div className="contact-info">
                    <h3>Contact Me</h3>
                    <p>
                      Email:{" "}
                      <a href="mailto:virvijay37@gmail.com">
                        virvijay37@gmail.com
                      </a>
                    </p>

                    <p>
                      Phone: <a href="tel:+917347200037">+91 73472 00037</a>
                    </p>

                    <p>
                      Twitter(X):{" "}
                      <a href="https://x.com/virvijay37" target="_blank">
                        @virvijay37
                      </a>
                    </p>

                    <p>
                      LinkedIn:{" "}
                      <a
                        href="https://www.linkedin.com/in/vijayvir-singh-19973969/details/recommendations/"
                        target="_blank"
                      >
                        Vijayvir Singh
                      </a>
                    </p>
                  </div>
                </section>
              </div>

              <footer className="background-content">
                <p>&copy; 2024 Vijayvir Singh. All rights reserved.</p>
              </footer>
            </div>
          </div>
    </div>
  );
}

export default UpperContent;
