import logo from "./logo.svg";
import "./Home.css";

import React, { useEffect } from "react";
import "./Voronoi.css"; // Add CSS for Voronoi styling

import "./nameAnimation.css";

import MatrixEffect from "./MatrixEffect";
import UpperContent from "./UpperContent";
import "./UpperContent.css";

function Home() {


  return (
    <div>
      <div className="wrapper">
        <MatrixEffect className="matrix-effect" />
        <div className="MainIntro">
        <UpperContent/>
        </div>
      </div>
    </div>
  );
}

export default Home;
