import React, { useEffect, useRef } from 'react';

const MatrixEffect = () => {
  const canvasRef = useRef(null);
  const fontSize = 10;
  const letters = '01456789ਅਆਇਈਉਊਏਐਓਔਕਖਗਘਚਛਜਝਟਠਡਢਤਥਦਧਨਪਫਬਭਮਯਰਲਵਸਹabcdefghijklmnopqrstuvwxyz'.split('');
  let columns = Math.floor(window.innerWidth / fontSize);
  let drops = new Array(columns).fill(1);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Function to update canvas dimensions and recalculate columns and drops on resize
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      columns = Math.floor(window.innerWidth / fontSize);
      drops = new Array(columns).fill(1); // Reset drops for new width
    };

    // Set canvas dimensions initially
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Attach resize event listener
    window.addEventListener('resize', handleResize);

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)'; // Creates fading effect
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Clear the canvas

      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = '#0f0'; // Green matrix color
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        // Move the drop down
        drops[i]++;

        // Reset drop to top randomly
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
          drops[i] = 0;
        }
      }
    };

    // Start the drawing loop (30 FPS)
    const intervalId = setInterval(draw, 60);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId);
    };
  }, [letters, fontSize]);

  return <canvas ref={canvasRef} />;
};

export default MatrixEffect;
